import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { GatewayStatus, useGateway } from '@civic/solana-gateway-react';
import { CandyMachineAccount } from './candy-machine';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';


export const CTAButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
  background-color: #FFFFFF !important;
  min-width: 120px !important;
  font-size: 1em !important;
`;

export const MintButton = ({
                               onMint,
                               candyMachine,
                               isMinting,
                               isEnded,
                               isActive,
                               isSoldOut
                           }: {
    onMint: () => Promise<void>;
    candyMachine?: CandyMachineAccount;
    isMinting: boolean;
    isEnded: boolean;
    isActive: boolean;
    isSoldOut: boolean;
}) => {
    const {requestGatewayToken, gatewayStatus} = useGateway();
    const [clicked, setClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    useEffect(() => {
        setIsVerifying(false);
        if (gatewayStatus === GatewayStatus.COLLECTING_USER_INFORMATION && clicked) {
            // when user approves wallet verification txn
            setIsVerifying(true);
        } else if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
            console.log('Verified human, now minting...');
            onMint();
            setClicked(false);
        }
    }, [gatewayStatus, clicked, setClicked, onMint]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', gap: 20}}>
            <CTAButton
                disabled={
                    clicked ||
                    candyMachine?.state.isSoldOut ||
                    isSoldOut ||
                    isMinting ||
                    isEnded ||
                    !isActive ||
                    isVerifying
                }
                onClick={async () => {
                    if (isActive && candyMachine?.state.gatekeeper && gatewayStatus !== GatewayStatus.ACTIVE) {
                        console.log('Requesting gateway token');
                        setClicked(true);
                        await requestGatewayToken();
                    } else {
                        console.log('Minting...');
                        await onMint();
                    }
                }}
                variant="contained"
            >
                {!candyMachine ? (
                    "CONNECTING..."
                ) : candyMachine?.state.isSoldOut || isSoldOut ? (
                    'SOLD OUT'
                ) : isActive ? (
                    isVerifying ? 'VERIFYING...' :
                        isMinting || clicked ? (
                            <CircularProgress/>
                        ) : (
                            "MINT"
                        )
                ) : isEnded ? "ENDED" : (candyMachine?.state.goLiveDate ? (
                    "SOON"
                ) : (
                    "UNAVAILABLE"
                ))}
            </CTAButton>
             <CrossmintPayButton
                collectionTitle="Hungry Robot Chicken Club"
                collectionDescription={`Hungry Robot Chicken Club (HRCC) is a collection of 2,222 unique digital Hungry Robot NFT collectibles living in the Solana ecosystem. Your Hungry Robot makes you part of the rapidly growing Sticky’s community and provides you access to exclusive member-only benefits.

The initial benefits to HRCC holders are comprised of discounts on Sticky’s menu items, access to exclusive merchandise, and more! Holder benefit and utility offerings will constantly evolve as these Hungry Robot’s fuel their travels through the HRCC roadmap with what else, Chicken!`}
                clientId={process.env.REACT_APP_CROSSPAY_CLIENT_ID!}
            />
        </div>

    );
};
